<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item v-if="this.user.user.roles[0].id == 1" :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('xtgl')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('yhgl')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <div class="search">
                        <div class="searchleft">
                            <el-form :inline="true" :model="searchform" class="demo-form-inline" @submit.native.prevent >
                                <el-form-item :label="$t('gjz')">
                                    <el-input v-model="searchform.blurry" @keyup.enter.native="onSubmit" size="small" :placeholder="$t('ncdh')">
                                    </el-input>
                                </el-form-item>
                                <el-form-item v-if="searchform.groupId == null" :label="$t('role')">
                                    <el-select size="small" v-model="searchform.roleId" style="width:180px;" :placeholder="$t('qxzrole')"  >
                                        <el-option :label="$t('buxian')" :value="null"></el-option>
                                        <el-option v-for="(item,index) in rolelist" :label="item.name" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item style="margin-top: 60;" label="国家" >
                                    <el-select v-model="searchform.nation" placeholder="请选择国家">
                                        <el-option :label="$t('buxian')" :value="null"></el-option>
                                        <el-option v-for="item in nationlist" :key="item.values" :label="item.name" :value="item.values"></el-option>
                                        <!-- <el-option v-for="(item,index) in options" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="状态">
                                    <el-select v-model="searchform.enabled" placeholder="请选择" style="width: 110px;">
                                        <el-option label="不限" value=""></el-option>
                                        <el-option label="激活" value="1"></el-option>
                                        <el-option label="冻结" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button size="small" type="primary" @click="onSubmit">{{$t('query')}}</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="searchright">
                            <el-button size="small" type="primary" @click="openadd">+{{$t('adduser')}}</el-button>
                        </div>
                    </div>
                    <el-table :data="list"
                        :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}"
                        stripe border style="width: 100%">
                        <el-table-column prop="username" :label="$t('dlm')">
                        </el-table-column>
                        <el-table-column prop="nickName" :label="$t('nc')">
                        </el-table-column>
                        <!-- <el-table-column prop="phone" :label="$t('phone')"></el-table-column> -->
                        <el-table-column prop="email" label="邮箱"></el-table-column>
                        <el-table-column prop="mchId" label="mchId"></el-table-column>
                        <el-table-column prop="role" :label="$t('role')">
                            <template slot-scope="scope">
                                {{scope.row.roles[0].name}}
                            </template>
                        </el-table-column>
                        <!-- <el-table-column size="small" prop="groupName" :label="$t('gsxz')">
                        </el-table-column> -->
                        <!-- <el-table-column prop="nation" label="国家"></el-table-column> -->
                        <el-table-column prop="roleDesc" :label="$t('status')" width="70">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.enabled" :disabled="scope.row.id === 1"
                                    active-color="#409EFF" inactive-color="#F56C6C"
                                    @change="changeEnabled(scope.row, scope.row.enabled,)" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="clabePrefectura" label="clabe号段" width="58px"></el-table-column>
                        <el-table-column prop="groupName" label="上级商户名称"></el-table-column>
                        <el-table-column prop="payType" label="放款渠道" width="98px"></el-table-column>
                        <el-table-column prop="reType" label="收款渠道" width="98px"></el-table-column>
                        <el-table-column prop="detain" label="收款扣留比例" width="88px"></el-table-column>
                        <el-table-column prop="withdraw" label="收款提现比例" width="88px"></el-table-column>
                        <el-table-column prop="minCost" label="最低金额" width="88px"></el-table-column>
                        <el-table-column prop="extraCost" label="代付单笔金额" width="88px"></el-table-column>
                        <el-table-column prop="extraOutCost" label="代收单笔金额" width="88px"></el-table-column>
                        <el-table-column prop="fixedAmount" label="预留固定金额" width="88px"></el-table-column>
                        <el-table-column prop="taxType" label="扣税方式" width="128px">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.taxType==1" type="primary" size="mini">税后</el-tag>
                                <el-tag v-if="scope.row.taxType==2" type="primary" size="mini">自动扣除7天前</el-tag>
                                <el-tag v-if="scope.row.taxType==3" type="primary" size="mini">实时</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="level" :label="$t('zhdlsh')" width="98px">
                            <template slot-scope="scope">
                                {{scope.row.updateTime|changedate}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="nation" label="国家" width="88px"></el-table-column>
                        <el-table-column fixed="right" prop="jobName" :label="$t('operator')" width="300">
                            <template slot-scope="scope">
                                <el-button v-if="user.user.id!=scope.row.id" size="small" @click="openedit(scope.row)" type="primary" icon="el-icon-edit"
                                    circle plain style="margin-right:5px;">
                                </el-button>
                                <!-- <el-popconfirm :title="$t('isczmm')" @confirm="resetpass(scope.row)">
                                    <el-button size="small" type="primary" icon="el-icon-s-claim" circle plain
                                        slot="reference" style="margin-right:5px">
                                    </el-button>
                                </el-popconfirm> -->
                                <!-- <el-popconfirm title="确定要删除吗？" @confirm="deleteuser(scope.row)">
                                    <el-button size="small" type="warning" icon="el-icon-delete-solid" circle slot="reference" plain></el-button>
                                </el-popconfirm> -->
                                <el-button v-if="user.user.id!=scope.row.id" size="small" @click="estimate(scope.row)" 
                                    title="配置服务费" type="primary" icon="el-icon-circle-plus-outline" 
                                    circle plain style="margin-left:5px;">
                                </el-button>
                                <el-button v-if="user.user.id!=scope.row.id" size="small" @click="openadd1(scope.row)" 
                                    title="STP充值" type="primary" icon="el-icon-bank-card" 
                                    circle plain style="margin-left:5px;">
                                </el-button>
                                <el-button v-if="user.user.id!=scope.row.id && scope.row.clabePrefectura == null && scope.row.roles[0].id == 2" size="small" 
                                    title="分配clabe号段" @click="openadd2(scope.row)" type="primary" icon="el-icon-s-finance" 
                                    circle plain style="margin-left:5px;">
                                </el-button>
                                <el-button v-if="user.user.id != scope.row.id && scope.row.roles[0].id == 2" size="small" 
                                    title="配置放款渠道" @click="openadd3(scope.row)" type="primary" icon="el-icon-finished" 
                                    circle plain style="margin-left:5px;">
                                </el-button>
                                <el-button v-if="user.user.id != scope.row.id && scope.row.roles[0].id == 2" size="small" 
                                    title="统计数据查看" @click="data(scope.row)" type="primary" icon="el-icon-view" 
                                    circle plain style="margin-left:5px;">
                                </el-button>
                                <el-button v-if="user.user.id != scope.row.id && scope.row.roles[0].id == 2" size="small" 
                                    title="每日数据查看" @click="data1(scope.row)" type="primary" icon="el-icon-search" 
                                    circle plain style="margin-left:5px;">
                                </el-button>
                                <el-button v-if="user.user.id != scope.row.id && scope.row.roles[0].id == 2" size="small" 
                                    title="添加白名单" @click="addIp(scope.row)" type="primary" icon="el-icon-coordinate" 
                                    circle plain style="margin-left:5px;">
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="page">
                        <app-page :total="total" :current="pageNo" :pageSize="pageSize"
                            @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
                    </div>
                </div>
            </div>
        </div>
        <pop-up :title="status==1?$t('adduser'):$t('edituser')" width="30%" :isshow="showadd" :haveconfirm="true" @close="getclose"
            @confirm="getconfirm">
            <div slot="box">
                <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small" label-width="160px">
                    <el-form-item :label="$t('dlm')" prop="username">
                        <el-input v-model="form.username" :disabled="status==2?true:false" />
                    </el-form-item>
                    <!-- <el-form-item :label="$t('phone')" prop="phone">
                        <el-input v-model.number="form.phone" :maxlength="11"/>
                    </el-form-item> -->
                    <el-form-item label="邮箱" prop="email">
                        <el-input v-model="form.email" />
                    </el-form-item>
                    <el-form-item :label="$t('nc')" prop="nickName">
                        <el-input v-model="form.nickName"/>
                    </el-form-item>
                    <!-- <el-form-item :label="$t('gender')">
                        <el-radio-group v-model="form.gender" style="width: 178px">
                            <el-radio label="男">{{$t('nan')}}</el-radio>
                            <el-radio label="女">{{$t('nv')}}</el-radio>
                        </el-radio-group>
                    </el-form-item> -->
                    <el-form-item :label="$t('status')">
                        <el-radio-group v-model="form.enabled">
                            <el-radio :label="true">{{$t('jihuo')}}</el-radio>
                            <el-radio :label="false">冻结</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item :label="$t('role')" >
                        <el-select v-model="form.roles[0].id" :placeholder="$t('qxzrole')">
                            <el-option v-for="item in rolelist" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="form.roles[0].id == 5" style="margin-top: 60;" label="绑定上级商户" >
                        <el-select v-model="form.groupId" :placeholder="$t('qxz')">
                            <el-option v-for="item in mchlist" :key="item.id" :label="item.nickName" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="form.roles[0].id == 3" style="margin-top: 60;" label="绑定卡号" >
                        <el-select v-model="form.cardId" multiple :placeholder="$t('qxz')">
                            <el-option v-for="item in cardlist" :key="item.id" :label="item.cuenta" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="form.roles[0].id == 2 || form.roles[0].id == 5" style="margin-top: 60;" label="国家" >
                        <el-select v-model="form.nation" placeholder="请选择国家">
                            <el-option v-for="item in nationlist" :key="item.values" :label="item.name" :value="item.values"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up :title="'STP充值/扣除'" :isshow="showadd1" :haveconfirm="true" @close="getclose1" @confirm="getconfirm1">
            <div slot="box" style="width: 700px;">
                <el-form ref="form" :inline="true" :model="form1" :rules="rules" size="small">
                    <el-form-item label="类型:">
                        <el-select v-model="form1.mold" placeholder="请选择">
                            <el-option label="充值" value="0"></el-option>
                            <el-option label="扣除" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="金额:">
                        <el-input-number v-model="form1.moeny" />
                    </el-form-item>
                    <el-form-item label="服务费:">
                        <el-input-number v-model="form1.cost" />
                    </el-form-item> 
                    <el-form-item label="充值目的">
                        <el-input type="textarea" v-model="form1.comentarios"></el-input>
                    </el-form-item> 
                </el-form>
            </div>
        </pop-up>
        <pop-up :title="'分配clabe号段'" :isshow="showadd2" :haveconfirm="true" @close="getclose2" @confirm="getconfirm2">
            <div slot="box" style="width: 700px;">
                <el-form ref="form" :inline="true" :model="form2" :rules="rules" size="small">
                    <el-form-item label="clabe号段:">
                        <el-select v-model="form2.id" :placeholder="$t('qxz')">
                            <el-option v-for="item in clabeList" :key="item.id" :label="item.prefecturaCode" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="渠道:">
                        <el-radio-group v-model="form2.channel">
                            <el-radio :label="1">FIRST_KEY</el-radio>
                            <el-radio :label="2">CNERGIES</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="form2.channel == 2" style="margin-top: 60;" label="子clabe号段:" >
                        <el-select v-model="form2.prefecturaCodeChild" :placeholder="$t('qxz')">
                            <el-option label="01" value="01"></el-option>
                            <el-option label="02" value="02"></el-option>
                            <el-option label="03" value="03"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up :title="'配置渠道'" :isshow="showadd3" :haveconfirm="true" @close="getclose3" @confirm="getconfirm3">
            <div slot="box" style="width: 700px;">
                <el-form ref="form" :inline="true" :model="form3" :rules="rules" size="small">
                    <el-form-item label="放款渠道:">
                        <el-select v-model="form3.id" placeholder="请选择">
                            <el-option label="ampay" value="0"></el-option>
                            <el-option label="拉美" value="1"></el-option>
                            <el-option label="genpay" value="2"></el-option>
                            <el-option label="opm" value="3"></el-option>
                            <el-option label="ascoampay" value="4"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="收款渠道:">
                        <el-select v-model="form3.reId" placeholder="请选择">
                            <el-option label="ampay" value="0"></el-option>
                            <el-option label="拉美" value="1"></el-option>
                            <el-option label="genpay" value="2"></el-option>
                            <el-option label="opm" value="3"></el-option>
                            <el-option label="ascoampay" value="4"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="扣留收款金额比例:">
                        <el-input-number v-model="form3.detain" :max="1" :min="0"/>
                    </el-form-item>
                    <el-form-item label="扣留提现金额比例:">
                        <el-input-number v-model="form3.withdraw" :max="1" :min="0"/>
                    </el-form-item>
                    <el-form-item label="最低金额:">
                        <el-input-number v-model="form3.minCost"/>
                    </el-form-item>
                    <el-form-item label="代付单笔金额:">
                        <el-input-number v-model="form3.extraCost"/>
                    </el-form-item>
                    <el-form-item label="代收单笔金额:">
                        <el-input-number v-model="form3.extraOutCost"/>
                    </el-form-item>
                    <el-form-item label="预留固定金额:">
                        <el-input-number v-model="form3.fixedAmount"/>
                    </el-form-item>
                    <el-form-item label="扣税类型:">
                        <el-select v-model="form3.taxType" placeholder="请选择">
                            <el-option label="税后" value="1"></el-option>
                            <el-option label="自动扣除7天前" value="2"></el-option>
                            <el-option label="实时" value="3"></el-option>
                            <el-option label="无" value="4"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>

        <pop-up :title="'配置ip'" :isshow="showadd4" :haveconfirm="true" @close="getclose4" @confirm="getconfirm4">
            <div slot="box" style="width: 300px;">
                <el-form ref="form" :inline="true" :model="form4" :rules="rules" size="small">
                    <el-form-item label="ip地址:">
                        <el-input v-model="form4.ip"/>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
    import apiurl from "@/api/sys"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {
        LOAD_CHILDREN_OPTIONS
    } from '@riophae/vue-treeselect'
    export default {
        components: {
            Treeselect
        },
        data() {
            var rolesValid = (rule, value, callback) => {
                if (value[0].id) {
                    callback()
                } else {
                    callback(new Error(this.$t('qxzrole')));
                }
            }
            return {
                // options: [{
                //     value: 'mx',
                //     label: '墨西哥'
                // }, {
                //     value: 'in',
                //     label: '印度'
                // }, {
                //     value: 'in',
                //     label: '哥伦比亚'
                // }],
                enabledTypeOptions: [{
                    key: 'true',
                    display_name: this.$t('jihuo')
                },{
                    key: 'false',
                    display_name: '冻结'
                }],
                form3: {
                    id: null,
                    orgId: null,
                    reId: null,
                    detain: null,
                    withdraw: null,
                    taxType: null,
                    minCost: null,
                    extraCost: null,
                    extraOutCost: null,
                    fixedAmount: null,
                },
                form4: {
                    orgId: null,
                    ip: null,
                },
                form2: {
                    id: null,
                    orgId: null,
                    channel: null,
                    prefecturaCodeChild: null,
                },
                form1: {
                    id: null,
                    moeny: null,
                    comentarios: null,
                    status: 2,
                    type: 4,
                    iden: 2,
                    orgId: null,
                    channel:1,
                    cost: null,
                    mold: null,
                },
                form: {
                    id: null,
                    username: null,
                    nickName: null,
                    gender: '男',
                    enabled: true,
                    groupName:null,
                    groupId:null,
                    roles: [{
                        id: null
                    }],
                    phone: null,
                    nation: null,
                    email: null,
                    cardId: null,
                },
                searchform: {
                    blurry: "",
                    roleId: null
                },
                loading: false,
                showadd: false,
                showadd1: false,
                showadd2: false,
                showadd3: false,
                showadd4: false,
                list: [],
                total: 1,
                pageNo: 1,
                pageSize: 10,
                keyword: '',
                user:null,
                roleId:null,
                rolelist: [],
                mchlist:[],
                cardlist:[],
                clabeList:[],
                nationlist:[],
                status: 1,
                rules: {
                    username: [{
                            required: true,
                            message: this.$t('qsryhm'),
                            trigger: 'blur'
                        },
                        {
                            min: 2,
                            max: 20,
                            message: '长度在 2 到 20 个字符',
                            trigger: 'blur'
                        }
                    ],
                    nickName: [{
                            required: true,
                            message: this.$t('qsryhnc'),
                            trigger: 'blur'
                        },
                        {
                            min: 2,
                            max: 20,
                            message: '长度在 2 到 20 个字符',
                            trigger: 'blur'
                        }
                    ],
                    email: [{
                            required: true,
                            message: this.$t('qsryxdz'),
                            trigger: 'blur'
                        },
                        {
                            type: 'email',
                            message: this.$t('qsrzqyxdz'),
                            trigger: 'blur'
                        }
                    ],
                    phone: [{
                        required: true,
                        message: this.$t('qsrsjh'),
                        trigger: 'blur'
                    }],
                    roles: [{
                        required: true,
                        validator: rolesValid,
                        trigger: 'change'
                    }],
                }
            }
        },
        created() {
            this.user=JSON.parse(localStorage.getItem("userinfo"))
            this.searchform.roleId = this.$route.query.roleId
            this.getList()
            this.getrole()
            this.getclabeList()
            this.getmchlist()
            this.getcardlist()
            this.getnation()
        },
        methods: {
            getnation() {
                _api.get(apiurl.nationlist, {}).then(res => {
                    let newarr = []
                    for(let item of res.data){
                        newarr.push(item)
                    }
                    this.nationlist = newarr
                })
            },
            resetpass(row) {
                _api.post(apiurl.resetPass, {
                    userId: row.id
                }).then(res => {
                    if (res) {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('czcg')
                        })
                    } else {
                        this.$notify.error({
                            title: this.$t('shibai'),
                            message: this.$t('czsb')
                        })
                    }
                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    });
                })
            },
            getmchlist(){
                _api.get(apiurl.users, {
                    nation:localStorage.getItem("nation"),
                    roleId: 2,
                }).then(res => {
                    this.mchlist = res.content
                })
            },
            getcardlist() {
                _api.get(apiurl.cards, {
                    nation:localStorage.getItem("nation"),
                    iden:2
                }).then(res => {
                    this.cardlist = res.data
                })
            },
            estimate(row){
                this.$router.push({path:"/addpay",query:{accountId:row.id}})
            },
            data(row){
                this.$router.push({path:"/datapay",query:{userId:row.id}})
            },
            data1(row){
                this.$router.push({path:"/datetable",query:{userId:row.id}})
            },
            deleteuser(row) {
                _api.delete(apiurl.users, row.id).then(res => {
                    this.$notify({
                        title: this.$t('success'),
                        message: this.$t('delSuccess'),
                        type: 'success'
                    });
                    this.pageNo = 1
                    this.getList()

                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    });
                })
            },
            openadd() {
                this.showadd = true
                this.status = 1
                this.form = {
                    id: null,
                    username: null,
                    nickName: null,
                    email: null,
                    enabled: true,
                    roles: [{
                        id: null
                    }],
                    email: null,
                    cardId: null,
                    nation: null,
                }
            },
            openadd1(row) {
                this.showadd1 = true
                this.form1 = {
                    id: null,
                    moeny: null,
                    comentarios: null,
                    status:2,
                    type:4,
                    iden:2,
                    channel:1,
                    orgId: row.id,
                    cost: null,
                    mold: null,
                }
            },
            openadd2(row) {
                this.showadd2 = true
                this.form2 = {
                    id: null,
                    orgId: row.id,
                    channel: null,
                    prefecturaCodeChild: null,
                }
            },
            openadd3(row) {
                this.showadd3 = true
                this.form3 = {
                    id: row.payId,
                    orgId: row.id,
                    reId: row.reId,
                    detain: row.detain,
                    withdraw: row.withdraw,
                    taxType: row.taxType,
                    minCost: row.minCost,
                    extraCost: row.extraCost,
                    extraOutCost: row.extraOutCost,
                    fixedAmount:  row.fixedAmount,
                }
            },
            addIp(row) {
                this.showadd4 = true
                this.form4 = {
                    orgId: row.mchId,
                    ip: row.ip,
                }
            },
            openedit(row) {
                console.log(row)
                this.status = 2
                this.form.id = row.id
                this.form.username = row.username
                this.form.nickName = row.nickName
                this.form.enabled = row.enabled
                this.form.roles[0].id = row.roles[0].id
                this.form.email= row.email,
                this.form.nation= row.nation,
                this.form.cardId= row.cardId,
                this.showadd = true
            },
            changeEnabled(data, val) {
                this.$confirm('确认要执行操作吗, 是否继续？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.status = 2
                    this.form.id = data.id
                    this.form.username = data.username
                    this.form.nickName = data.nickName
                    this.form.enabled = val
                    this.form.roles[0].id = data.roles[0].id
                    this.form.nation= row.nation,
                    this.form.email= row.email,
                    this.form.cardId= row.cardId,
                    _api.put(apiurl.users, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('czcg')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            username: null,
                            nickName: null,
                            enabled: true,
                            roles: [{
                                id: null
                            }],
                            nation: null,
                            cardId: null,
                        }

                    }).catch(err => {
                        this.$notify.error({
                            title:this.$t('error'),
                            message: err.data.message
                        })
                    })
                }).catch(() => {
                    data.enabled = !data.enabled
                })
            },
            getList() {
                this.loading = true
                _api.get(apiurl.users, {
                    page: this.pageNo,
                    size: this.pageSize,
                    blurry: this.searchform.blurry,
                    roleId: this.searchform.roleId,
                    nation: this.searchform.nation,
                    groupId: this.searchform.groupId,
                    enabled: this.searchform.enabled,
                }).then(res => {
                    this.loading = false
                    this.list = res.content
                    this.total = res.totalElements
                })
            },
            onSubmit() {
                this.pageNo = 1
                this.getList()
            },
            getrole() {
                _api.get(apiurl.roles, {}).then(res => {
                    let newarr=[]
                    for(let item of res.content){
                        newarr.push(item)
                    }
                    this.rolelist = newarr
                })
            },
            getgrouplist(){
                _api.get(apiurl.group, {
                    nation:localStorage.getItem("nation")
                }).then(res => {
                    this.grouplist = res.data
                })
            },
            getclabeList() {
                _api.get(apiurl.clabePrefectura, {
                }).then(res => {
                    this.clabeList = res.data
                })
            },
            getconfirm() {
                if(this.form.roles[0].id==null || this.form.roles[0].id==""){
                    this.$message.error(this.$t('qxzrole'))
                    return
                }
                
                if (this.form.cardId != null) {
                    this.form.cardId = this.form.cardId.join(",")
                }
                if (this.status == 1) { //如果是添加
                    _api.post(apiurl.users, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('addSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            username: null,
                            nickName: null,
                            enabled: true,
                            roles: [{
                                id: null
                            }],
                            nation: null,
                            email: null,
                            cardId:null,
                        }

                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                } else { //如果是编辑
                    _api.put(apiurl.users, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('editSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            username: null,
                            nickName: null,
                            enabled: true,
                            roles: [{
                                id: null
                            }],
                            email: null,
                            nation: null,
                            cardId:null,
                        }
                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                }
            },

            getconfirm1() {
                _api.post(apiurl.mencionar, this.form1).then(res => {
                    this.$notify.success({
                        title: this.$t('success'),
                        message: this.$t('addSuccess')
                    }),
                    this.showadd1 = false
                    this.form1 = {
                        id: null,
                        moeny: null,
                        comentarios: null,
                        status:2,
                        type:4,
                        iden:2,
                        channel:1,
                        cost: null,
                        mold: null,
                    }
                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    })
                })
            },

            getconfirm2() {
                _api.post(apiurl.clabePrefectura, this.form2).then(res => {
                    this.$notify.success({
                        title: this.$t('success'),
                        message: '配置成功'
                    })
                    this.showadd2 = false
                    this.form2 = {
                        id: null,
                        orgId: null,
                        channel: null,
                        prefecturaCodeChild: null,
                    }
                    this.getList()
                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    })
                })
            },

            getconfirm3() {
                _api.post(apiurl.payType, this.form3).then(res => {
                    this.$notify.success({
                        title: this.$t('success'),
                        message: '配置成功'
                    })
                    this.showadd3 = false
                    this.form3 = {
                        id: null,
                        orgId: null,
                        reId: null,
                        detain: null,
                        withdraw: null,
                        taxType: null,
                        minCost: null,
                        extraCost: null,
                        extraOutCost: null,
                        fixedAmount: null,
                    }
                    this.getList()
                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    })
                })
            },

            getconfirm4() {
                _api.post(apiurl.addIp, this.form4).then(res => {
                    this.$notify.success({
                        title: this.$t('success'),
                        message: '配置成功'
                    })
                    this.showadd4 = false
                    this.form4 = {
                        orgId: null,
                        ip: null,
                    }
                    this.getList()
                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    })
                })
            },

            getclose() {
                this.$refs.form.resetFields();
                this.showadd = false
            },

            getclose1() {
                this.$refs.form.resetFields();
                this.showadd1 = false
            },

            getclose2() {
                this.$refs.form.resetFields();
                this.showadd2 = false
            },

            getclose3() {
                this.$refs.form.resetFields();
                this.showadd3 = false
            },
            
            getclose4() {
                this.$refs.form.resetFields();
                this.showadd4 = false
            },

            handleNodeClick(mydata, node, obj) {
                this.searchform.blurry = ""
                this.loading = true
                this.pageNo=1
                this.getList()
            },
            handchange(data) { //分页Size变化
                this.pageSize = data
                this.pageNo = 1
                this.getList()
            },
            currentchange(data) { //当前页变化
                console.log(data)
                this.pageNo = data
                this.getList()
            },
        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>